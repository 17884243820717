import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";

export interface ILoader {
  isLoading: boolean
}

@Module
export default class LoaderModule extends VuexModule implements ILoader {
  isLoading = false;

  /**
   * Get selected book
   * @returns IBooks
   */
  get loading(): boolean {
    return this.isLoading;
  }

  @Mutation
  [Mutations.SET_LOADING](payload) {
    this.isLoading = payload;
  }

  @Action
  [Actions.SET_IS_LOADING](payload) {
    this.context.commit(Mutations.SET_LOADING, payload);
  }
}
