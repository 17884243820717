const { VUE_APP_CLIENT_ID, VUE_APP_ISSUER, VUE_APP_OKTA_TESTING_DISABLEHTTPSCHECK } = process.env

const BASENAME = ''; // process.env.NODE_ENV === 'production' ? '/okta-hosted-login' : '';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;

//alert(JSON.stringify(process.env));
export default {
  oidc: {
    clientId: VUE_APP_CLIENT_ID,
    issuer: VUE_APP_ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    testing: {
      disableHttpsCheck: VUE_APP_OKTA_TESTING_DISABLEHTTPSCHECK
    }
  },
  /*
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages'
  },*/
  app: {
    basename: BASENAME
  }
}