import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import { IBooks } from '@/core/data/books';

export interface IBookInfo {
  selectedBook: IBooks
}

@Module
export default class BookModule extends VuexModule implements IBookInfo {
  selectedBook = {} as IBooks;
  refetchBookRequest: boolean = false;

  /**
   * Get selected book
   * @returns IBooks
   */
  get getSelectedBook(): IBooks {
    return this.selectedBook;
  }

  @Mutation
  [Mutations.SET_SELECTED_BOOK](payload) {
    console.log("payload", payload);
    this.selectedBook = payload;
  }

  @Action
  [Actions.SELECT_BOOK](payload) {
    console.log("payload", payload);
    this.context.commit(Mutations.SET_SELECTED_BOOK, payload);
  }

  get refetchBookReq() : boolean {
    return this.refetchBookRequest;
  }

  @Mutation
  [Mutations.SET_REFETCH_BOOK_REQUEST](payload) {
    this.refetchBookRequest = payload;
  }

  @Action
  [Actions.SET_REFETCH_BOOK_REQ](payload) {
    this.context.commit(Mutations.SET_REFETCH_BOOK_REQUEST, payload);
  }
}
