
import { defineComponent, nextTick, onMounted, defineExpose } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import AuthRequiredModal from './components/AuthRequiredModal.vue';
import ApiService from "@/core/services/ApiService";

const onAuthRequired = (oktaAuth) => {
      if (!oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated) {
        // App initialization stage
        oktaAuth.signInWithRedirect();
      } else {
        // Ask the user to trigger the login process during token autoRenew process
        //this.$refs.authRequiredModal.showModal(oktaAuth);
        alert('hello');
      }
    };

export default defineComponent({
  name: "app",
  setup(props, context) {
    const store = useStore();

    onMounted(() => {
      ApiService.setHeader();
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
//      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });

    //defineExpose({ onAuthRequired })

/*
    context.expose({ onAuthRequired })
     

    return {
        onAuthRequired: onAuthRequired
      };
    */
   },
  expose: ['onAuthRequired'],
  methods: {
    onAuthRequired: onAuthRequired,
    login () {
      //this.$auth.signInWithRedirect({ originalUri: '/' })
    },
    async logout () {
      
    }
  }
});
