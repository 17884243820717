import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";

export interface ITable {
  count: number
}

@Module
export default class TableModule extends VuexModule implements ITable {
  count = 1;

  /**
   * Get selected book
   * @returns IBooks
   */
  get page(): number {
    return this.count;
  }

  @Mutation
  [Mutations.COMMIT_PAGE_COUNT](payload) {
    this.count = payload;
  }

  @Action
  [Actions.ACTION_PAGE_COUNT](payload) {
    this.context.commit(Mutations.COMMIT_PAGE_COUNT, payload);
  }
}
