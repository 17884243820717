import { createRouter, createWebHashHistory, RouteRecordRaw, createWebHistory } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { LoginCallback, navigationGuard } from '@okta/okta-vue';
import oktaAuth from '@/auth';
import ApiService from "@/core/services/ApiService";

const routes: Array<RouteRecordRaw> = [
  {
    name: 'login-callback',
    path: '/login/callback',
    component: LoginCallback
  },
  {
    path: "/sign-in",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      }
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    redirect: "/books",
    children: [
      {
        path: "/books",
        name: "books",
        component: () => import("@/views/books/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/book-requests",
        name: "book-requests",
        component: () => import("@/views/book-requests/Index.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// https://developer.okta.com/docs/guides/sign-into-spa-redirect/vue/main/#use-the-access-token

router.beforeEach((to, from, next) => {
  console.log('beforeEach to', to);
  console.log('beforeEach from', from);
  // reset config to initial state
  // store.commit(Mutations.RESET_LAYOUT_CONFIG);
  //store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });
  oktaAuth.tokenManager.get('idToken').then( (idToken : any) => {
    console.log('idToken x',idToken);
    if (idToken) {
      ApiService.setHeader();

      const claims =  Object.entries(idToken.claims).map(entry => ({ claim: entry[0], value: entry[1] }));
      const user = Object.fromEntries(
        claims.map(e => [(e as any).claim, (e as any).value])
      );
      console.log('user: ', user);
      console.log('idToken',idToken, claims);
      if (to.name == 'sign-in') {
        console.log('redirecting to books');
        next({ name: 'books' });
      }
      else {
        next();
      }
    }
    else {
      if(to.name != 'sign-in' && to.name != 'login-callback') {
        next({ name: "sign-in" });
      }
      else {
        next();
      }
    }
  
    //check if current user is authenticated
    if (!idToken) {      
      //next();
    }
  }); 

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  
});

router.afterEach((to, from) => {
  // console.log('afterEach', to, from);
  if (to.name == 'login-callback') {
    //router.push({ name: "books" });
  }
});

//router.beforeEach(navigationGuard);

export default router;
